<template>
  <div class="MyorderBox">
    <el-row>
        <el-col :span="24"   class="titbox">
            <el-col :span="8">
              <span :class="isOrderType==1?'title active':'title '" @click="initFun(1)">我的订单</span>
              <span :class="isOrderType==2?'title active':'title '" @click="initFun(2)">积分订单</span>
              <span  v-show="isJoinOrderFlag" :class="isOrderType==3?'title active':'title '" @click="initFun(3)">加盟订单</span> 
            </el-col>
        </el-col>
    </el-row>
    <!-- <p   class="NormalBar" v-if="isOrderType==1"></p> -->
    <!-- <p class="progressBar" v-if="isOrderType==2"></p> -->
    <p class="progressBar"></p>

    <el-row class="header"  v-if="isOrderType==1">
      <el-col :span="2">输入搜索：</el-col>
      <el-col :span="8"
        ><el-input
          placeholder="订单ID"
          v-model="OrderId"
          clearable
        ></el-input
      ></el-col>
      <el-col :span="6"
        ><el-button @click="search" class="search">查询</el-button></el-col
      >
    </el-row>
    <el-row class="header"  v-else-if="isOrderType==2">
      <el-col :span="2">输入搜索：</el-col>
      <el-col :span="8"
        ><el-input
          placeholder="订单编号"
          v-model="OrderSn"
          clearable
        ></el-input
      ></el-col>
      <el-col :span="6"
        ><el-button @click="search" class="search">查询</el-button></el-col
      >
    </el-row>

    <el-row class="header"  v-if="isOrderType==3">
      <el-col :span="2">加盟店铺：</el-col>
      <el-col :span="6">
        <!-- <el-input
          placeholder="订单ID"
          v-model="OrderId"
          clearable
        ></el-input> -->
        <el-select v-model="JoinShopVal" placeholder="请选择" @change="JoinShopSearch">
          <el-option
            v-for="item in JoinShoplist"
            :key="item.id"
            :label="item.enterpriseName"
            :value="item.id">
          </el-option>
        </el-select>
      </el-col>
      <!-- <el-col :span="6"><el-button @click="search" class="search">查询</el-button></el-col> -->
    </el-row>


    <el-row class="fromWraper">
      <Nav :obj="obj" @clickIndex="changePropsIndex"></Nav>
      <button  v-if="isOrderType==1" class="downBtn" @click="downFun()">
        <strong class="el-icon-download"></strong>订单导出
      </button>
    </el-row>
    <el-row>
      <ul class="ulBox">
        <li>
          <el-date-picker
            format="yyyy 年 MM 月"
            :picker-options="pickerBeginDateBefore"
            clear-icon=""
            prefix-icon="el-icon-arrow-down"
            v-model="dataValue"
            size="small"
            type="month"
            @change="ChangeDate"
          >
          </el-date-picker>
        </li>
        <li>订单详情</li>
        <li>数量</li>
        <li>交易金额</li>
        <li>交易状态</li>
        <li>操作</li>
      </ul>
    </el-row>
    <!-- 我的订单 订单详情 -->
    <div v-show="isOrderType==1">
      <Detail
        v-for="(item, index) in orderlist"
        :orderList="item"
        :key="index"
        @getData="getOrderList"
      ></Detail>
    </div>
    <div  v-show="isOrderType==2">
      <el-row class="Detailitem" v-for="(item, index) in orderlist" :key="index">
          <el-col :span="24" class="header" v-if="item.status!==1">
            <el-col :span="12">
              <el-col :span="8">{{ item.updateTime }}</el-col>
              <el-col :span="16">订单编号: {{ item.sn }}  </el-col>
            </el-col>
            <el-col :span="12">订单ID：{{ item.id }}</el-col>
          </el-col>
          <table class="main" v-if="item.status!==1">
            <tbody>
                <template>
                  <tr>
                    <td  class="tableBox" :width="400">
                      <div class="imgBox" @click="jumpGoodsDetail(item)">
                        <img :src="item.goodsPic | defaultImage" alt="" />
                      </div>
                      <div class="desBox">
                        <p class="desTit">
                          <span :title="item.goodsName">{{ item.goodName }}</span>
                          <span>x{{ item.goodsCount }}</span>
                        </p>
                      </div>
                    </td>
                    
                    <!-- 只需要展示1次 -->
                    <td>
                      <p class="text-center">{{ item.payablePoints  }}积分</p>
                    </td>
                    <td>
                      <!--  {{item.status}} -->
                      <p class="text-center">
                        {{ item.statusStr  }}  
                      </p>
                    </td>
                    <td >
                      <!-- <p
                         v-if="item.status==2" 
                        class="text-center textBtn"
                        @click="SureOrderFun(item.id)"
                      >
                        确认收货
                      </p>   
                         v-else
                      
                      -->
                     
                      <!-- <p
                        class="text-center textBtn"
                        type="text"
                        size="small"
                        @click="jumpDetail(item.id)"
                      >
                        订单详情
                      </p>
                      <p
                        v-if="item.orderType<3" 
                        class="text-center textBtn"
                        @click="jumpAgainOrder(item.id)"
                      >
                        再来一单
                      </p>
                      <p class="text-center textBtn"  v-show="item.status === 4 || item.status === 5"  @click="delOrder(item.id)">
                          删除订单
                        </p> 
                      <p
                        class="text-center textBtn"
                        v-if="
                          item.status == 2 || item.status === 3
                        "
                        @click="jumpRefund(item.id)"
                      >
                        申请售后
                      </p>
                      <p
                        class="text-center textBtn"
                        v-if="item.status === 3 || item.status === 4"
                        @click="DownInfoFun(item.id)"
                      >
                        下载订单药检报告
                      </p> -->
                      <el-popover
                        v-if="item.status === 3"
                        placement="left"
                        width="300"
                        trigger="click"
                        v-model="visible"
                      >
                        <h2 class="LogTit">
                          {{ logiVO }} <span>{{ logiNo }}</span>
                        </h2>
                        <div class="block">
                          <el-timeline>
                            <el-timeline-item
                              v-for="(activity, index) in activities"
                              :key="index"
                              :icon="activity.icon"
                              :type="activity.type"
                              :color="activity.color"
                              :size="activity.size"
                              :timestamp="activity.ftime"
                            >
                              <p>{{ activity.context }}</p>
                            </el-timeline-item>
                          </el-timeline>
                        </div>
                        <span
                          class="text-center textBtn"
                          slot="reference"
                          @click="CheckLogistics(item.expressSn,item.expressNameTag,item.expressName)"
                          >查看物流</span
                        >
                      </el-popover>
                       <p
                          class="text-center textBtn"
                          v-else
                        >
                          暂无操作
                        </p>
                    </td>
                  </tr>
                </template>
              </tbody>
          </table>
      </el-row>
    </div>




    <div v-show="isOrderType==3">
      <Detail
        v-for="(item, index) in JoinOrderlist"
        :orderList="item"
        :key="index"
        :JoinOrderFlag='true'
        @getData="getOrderList"
      ></Detail>
    </div>


    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="pagesize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import { userPointsNum ,JoinMemberStorelist} from 'api/member.js';
import { OrderList,PointsOrderList ,SureOrder,GetPointLog,JoinOrderList} from "api/order.js";
import Qs from "qs";
import { getToken } from "utils/session.js";
const Nav = () => import("components/common/Nav.vue");
const Detail = () => import("components/order/Detail.vue");
export default {
  name: "MyOrder",
  data() {
    return {
       visible: false,
      activities: [], //物流信息
      logiVO: "", //  物流
      logiNo: "", //  物流单号
      PointsId:"",
      isOrderType:1,//1:普通订单，2：积分订单，3：加盟订单
      isJoinOrderFlag:false,//初始不显示加盟订单

      OrderId:'',
      OrderSn:'',
      startTime: "",
      endTime: "",
      pagesize: 10,
      currentPage: 1,
      total: 0,
      inputConetent: "",
      dataValue: new Date(),
      statusType: '',
      //不能大于当前时间
      pickerBeginDateBefore: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      //导航栏信息
      obj: {
        content: [
          {
            type: 0,
            name: "全部",
          },
          {
            type: 1,
            name: "待付款",
          },
          {
            type: 2,
            name: "待发货",
          },
          {
            type: 3,
            name: "待收货",
          },
          {
            type: 4,
            name: "已完成",
          },
          {
            type: 5,
            name: "已取消",
          },
        ],
        currentIndex: 0,
      },
      orderlist: "",
      JoinShoplist:[],
      JoinShopVal:'',
      JoinOrderlist:''
    };
  },
  methods: {
    // 查詢物流
    CheckLogistics(sn,type,logiVO){
      let datas={
        logiNo:sn,
        logiCode:type,
      }
          GetPointLog(datas).then((data) => {
            console.log("积分订单查看1物流",data)
            if (data.data.code == 200) {
              this.logiVO =logiVO;
              this.logiNo = sn;
              if (data.data.data== "") {
                this.activities = [];
              } else {
                const routesArray = JSON.parse(data.data.data);


                console.log("routesArray查看1物流",routesArray)
                this.activities = routesArray;
                this.activities[0].color = "#0bbd87";
              }
            }
        })
    },
// 确认收货
SureOrderFun(id){
  SureOrder(id).then(res=>{
    console.log('aaaaaaaa',res)
  })
},


    downFun() {
      let startTime = "";
      if (this.startTime) {
        startTime = this.startTime + " " + "00:00:00";
      }
      let endTime = "";
      if (this.endTime) {
        endTime = this.endTime + " " + "23:59:59";
      }
      let memberStoreId = Qs.parse(sessionStorage.getItem("memberData"))
        .memberStoreId;
      const prames = {
        status: this.statusType,
        beginTime: startTime,
        endTime: endTime,
        memberStoreId: memberStoreId,
      };

      console.log(prames);
      this.$confirm("是否导出订单明细?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // 订单下载
          window.open(
            `/api/blade-order/order/export-order?Blade-Auth=${getToken()}&&status=${
              this.statusType
            }&&beginTime=${prames.beginTime}&&endTime=${prames.endTime}`,
            "_blank"
          );
        })
        .catch(() => {});
    },
    getDaysInOneMonth(year, month) {
      var d = new Date(year, month, 0);
      return d.getDate();
    },
    ChangeDate() {
      let currentTime = new Date(this.dataValue),
        year = currentTime.getFullYear(),
        month = currentTime.getMonth() + 1;
      let day = this.getDaysInOneMonth(year, month);
      if (month < 10) {
        this.startTime = year + "-0" + month + "-01";
        this.endTime = year + "-0" + month + "-" + day;
      } else {
        this.startTime = year + "-" + month + "-01";
        this.endTime = year + "-" + month + "-" + day;
      }
      if(this.isOrderType==1){
        this.getOrderList();
      }else if(this.isOrderType==2){
        this.getPointsList()
      }else{
          // 加盟订单
          this.getJoinList()
        }
    },
    changePropsIndex(params) {
      this.obj.currentIndex = params;
      this.statusType = params;
      this.currentPage = 1;

      if(this.isOrderType==1){
        this.getOrderList();
      }else if(this.isOrderType==2){
        this.getPointsList()
      }else{
          
          // 加盟订单
          this.getJoinList()
      }
    },
    // 输入订单ID查询
    search(){
      this.currentPage = 1;
      if(this.isOrderType==1){
        this.getOrderList();
      }else if(this.isOrderType==2){
        this.getPointsList()
      }
    },


// 加盟订单列表搜索
JoinShopSearch(){
  // alert(this.JoinShopVal)
  this.getJoinList()
},

    initFun(Types){

        if(Types==1){
          // 普通订单
          this.isOrderType=1
          this.getOrderList()
        }else if(Types==2){
          // 积分订单
          this.isOrderType=2
          // 获取积分数量  和积分人员编号
          if(this.PointsId==""){
              userPointsNum().then(res=>{
                this.PointsId=res.data.data.userId;
                this.getPointsList()
              })
          }else{
            this.getPointsList()
          }
        }else{
          // 加盟订单
          this.isOrderType=3
          this.getJoinList()
        }
    },
    // 积分订单接口调取
    getPointsList(){
       // 显示loading
       this.$loading({ fullscreen: true, background: "#ffffff45" });
       let datas = "";

       if (this.statusType > 0) {
        datas = {
          userId:this.PointsId,
          status:this.statusType,
          sn:this.OrderSn,
          isApp:'',
          size:this.pagesize,
          current: this.currentPage,
          beginOrderTime: this.startTime,
          endOrderTime: this.endTime,
        };
      } else {
        datas = {
          userId:this.PointsId,
          status:"",
          sn:this.OrderSn,
          isApp:'',
          size:this.pagesize,
          current: this.currentPage,
          beginOrderTime: this.startTime,
          endOrderTime: this.endTime,
        };
      }
      PointsOrderList(datas).then(res=>{
        this.orderlist = res.data.data.records;
         // 初始获取所有数据数量
        this.total = res.data.data.total;
         // 关闭loading
        this.$loading().close();
      })
    },
    // 普通订单接口
    getOrderList() {
      let datas = "";
      // 1：待支付，2待发货，3待收货，4已完成，5已取消   0全部
      if (this.statusType > 0) {
        datas = {
          id:this.OrderId,
          status: this.statusType,
          size: this.pagesize,
          current: this.currentPage,
          startTime: this.startTime,
          endTime: this.endTime,
        };
      } else {
        datas = {
          id:this.OrderId,
          size: this.pagesize,
          current: this.currentPage,
          startTime: this.startTime,
          endTime: this.endTime,
        };
      }
      // 显示loading
      this.$loading({ fullscreen: true, background: "#ffffff45" });
      //获取我的订单列表
      OrderList(datas).then((res) => {
        if (res.data.code === 400) {
          this.$message.error(res.data.msg);
        }
        this.orderlist = res.data.data.records;
        // 初始获取所有数据数量
        this.total = res.data.data.total;
        // 关闭loading
        this.$loading().close();
      });
    },


// 加盟订单接口   localStorage.setItem('erpNumber')
getJoinList(){
    let datas = "";
    // 显示loading
    this.$loading({ fullscreen: true, background: "#ffffff45" });
      // 1：待支付，2待发货，3待收货，4已完成，5已取消   0全部
      if (this.statusType > 0) {
        datas = {
          id:this.OrderId,
          status: this.statusType,
          size: this.pagesize,
          current: this.currentPage,
          startTime: this.startTime,
          endTime: this.endTime,
          storeId:this.JoinShopVal//加盟店铺id
        };
      } else {
       
        datas = {
          id:this.OrderId,
          size: this.pagesize,
          current: this.currentPage,
          startTime: this.startTime,
          endTime: this.endTime,
          storeId:this.JoinShopVal//加盟店铺id
        };

        console.log('222datasdatas',datas)
      }
    JoinOrderList(datas).then(res=>{
      console.log('加盟订单接口',res.data.data)

      this.JoinOrderlist=res.data.data.records
      this.total = res.data.data.total;
      // 关闭loading
      this.$loading().close();
  })
},

    handleSizeChange(val) {
      this.pagesize = val;
      let datas = "";
      if (this.statusType > 0) {
        datas = {
          current: this.currentPage,
          size: this.pagesize,
          status: this.statusType,
        };
      } else {
        // 传每页显示数据
        datas = {
          size: this.pagesize,
          current: this.currentPage,
        };
      }
      if(this.isOrderType==1){
        this.getOrderList(datas);
      }else if(this.isOrderType==2){
        this.getPointsList(datas)
      }else{
          // 加盟订单
          this.getJoinList(datas)
        }


    },
    handleCurrentChange(val) {
      this.currentPage = val;
      let datas = "";
      if (this.statusType > 0) {
        datas = {
          size: this.pagesize,
          current: this.currentPage,
          status: this.statusType,
        };
      } else {
        // 传当前页数
        datas = {
          size: this.pagesize,
          current: this.currentPage,
        };
      }
      if(this.isOrderType==1){
        this.getOrderList(datas);
      }else if(this.isOrderType==2){
        this.getPointsList(datas)
      }else{
          // 加盟订单
          this.getJoinList(datas)
        }
    },
  },
  created() {


    if(localStorage.getItem('childErpNumberCount')>0){
        this.isJoinOrderFlag=true
      // 有连锁门店  childErpNumberCount   大于0 表示有加盟店，展示图标，没有则隐藏

      let parentErpNumber={parentErpNumber:localStorage.getItem('erpNumber')}
       //  获取加盟门店列表接口
       JoinMemberStorelist(parentErpNumber).then(res=>{
        console.log('加盟门店列表',res.data.data)
        this.JoinShoplist=res.data.data
        // 初始加盟列表取第一个
        this.JoinShopVal=res.data.data[0].id
      })
    }else{
      this.isJoinOrderFlag=false
    }


    if (this.$route.params.type) {
      this.obj.currentIndex = Number(this.$route.params.type);
      this.statusType = this.$route.params.type;
      let datas = "";
      // 1：待支付，2待发货，3待收货，4已完成，5已取消
      if (this.statusType > 0) {
        datas = {
          status: this.statusType,
          size: this.pagesize,
          current: this.currentPage,
        };
      } else {
        datas = {
          size: this.pagesize,
          current: this.currentPage,
        };
      }
      this.getOrderList(datas);
    } else {
      this.getOrderList();
    }

    
  },
  components: {
    Nav,
    Detail,
  },
};
</script>
<style lang="less">
.text-center{
  color: #3c3c3c;
}
.el-popover {
  .LogTit {
    line-height: 30px;
    font-size: 14px;
    font-weight: bold;
    span {
      color: #666;
      font-size: 13px;
      font-weight: normal;
    }
  }
  .el-timeline-item__content {
    p {
      font-size: 13px;
      color: #666;
      line-height: 20px;
      padding: 5px 0;
    }
  }
}
.Detailitem {
  margin-bottom: 20px;
  background: #fff;
  font-size: 12px;
  color: #999;
  .header {
    padding: 10px;
    background: #eee;
    color: #3c3c3c!important;
  }
  table.main {
    width: 100%;
    border: 1px solid #ccc;
    border-collapse: collapse;
    border: none!important;
    .tableBox {
      padding: 10px 10px 10px 0;
      text-align: left;
      .slText {
        font-size: 26px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 10px;
        cursor: pointer;
        &:hover {
          color: #ff3333;
        }
      }
    }
    thead th {
      text-align: center;
      background-color: #fafafa;
    }
    th,
    td {
      border: 1px solid #e9eaec;
      line-height: 30px;
      text-align: center;
    }
    .imgBox {
      float: left;
      width: 72px;
      height: 72px;
      margin: 0 14px;
      border: 1px solid #f6f6f6;
      img {
        display: inline-block;
        width: 70px;
        height: 70px;
        overflow: hidden;
      }
    }
    .desBox {
      float: left;
      padding: 14px 0;
      width: calc(100% - 120px);
      .desTit {
        width: 100%;
        height: 20px;
        cursor: pointer;
        line-height: 20px;
        span:nth-child(1) {
          max-width: 200px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          font-size: 14px;
          font-weight: bold;
          color: #333;
          float: left;
        }
        span:nth-child(2) {
          font-size: 13px;
          color: #666;
          float: right;
        }
      }
      .desMsg {
        width: 100%;
        height: 20px;
        line-height: 20px;
        font-size: 13px;
        color: #999;
      }
    }
  }
}

.el-pager li.active {
  color: #ff3333;
}
.el-pager li:hover {
  color: #ff3333;
}
.MyorderBox {
  .nav {
    li {
      float: left;
      padding: 10px;
      color: #666666;
      font-size: 14px;
    }
    .active {
      color: #ff3333;
    }
  }
  .el-date-editor {
    .el-input__prefix {
      right: -20px;
    }
  }
  .el-input__inner {
    border: none;
    background: #f6f6f6;
  }
  .el-table .cell {
    font-size: 12px;
    color: #333;
  }

  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #ff3333;
    color: #fff;
  }
  /* 上一页，下一页样式 */
  .el-pagination button,
  .el-pagination span:not([class*="suffix"]) {
    background: #fff;
    color: #666;
    box-sizing: border-box;
  }

  /* 每个页码的样式 */
  .el-pagination.is-background .el-pager li {
    background: #fff;
    color: #666;
    box-sizing: border-box;
  }
  .el-pagination.is-background .el-pager li:not(.disabled):not(.active):hover {
    color: #ff3333;
    font-size: 14px;
  }
}
</style>
<style lang="less" scoped>
 .titbox{
        border-bottom:none;
        border-left: 2px solid #FF3333;

        .title{
            font-size: 16px;
            line-height: 22px;
            padding-left: 13px;
            color:#333333;
            font-weight: 600;
            cursor: pointer;
            &.active{
              color:#FF3333
            }
            &:nth-child(2){
                color: #666;
                 &.active{
                  color:#FF3333
                }
            }
            &:nth-child(3){
                color: #666;
                 &.active{
                  color:#FF3333
                }
            }
        }
    }
.header {
  padding-left:10px;
  padding-top: 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
  .search {
    margin-left: 20px;
  }
}
.el-pagination {
  padding-top: 20px;
  text-align: right;
}
.MyorderBox {
  .NormalBar{
    height: 20px;
  }
  .progressBar {
    height: 20px;
    margin-left: -20px;
    width: 100%;
    background: #f6f6f6;
    padding: 0 20px;
    margin-top: 20px;
  }
  .fromWraper {
    padding: 20px 0;
    position: relative;
    .downBtn {
      background: #ff3333;
      color: #fff;
      padding: 10px 10px;
      border: none;
      position: absolute;
      right: 10px;
      top: 12px;
      cursor: pointer;
      outline: none;
    }
  }

  .ulBox {
    background: #f6f6f6;
    height: 38px;
    line-height: 30px;
    margin-bottom: 20px;
    li {
      float: left;
      text-align: center;
      color: #666666;
      width: 128px;
      line-height: 38px;
      font-size: 12px;
    }
    li:nth-child(3) {
      width: 124px;
    }
    li:nth-child(4) {
      width: 140px;
    }
    li:nth-child(5) {
      width: 180px;
    }
    li:first-child {
      width: 180px;
    }
    li:last-child {
      width: calc(100% - 760px);
    }
  }

  .content {
    display: flex;
    align-items: center;
    background: #f6f6f6;
    height: 38px;
    line-height: 38px;
    margin-bottom: 20px;
    padding: 0 20px;
    font-size: 12px;
    color: #666666;
    .searchContent {
      display: flex;
      align-items: center;
    }
  }
  //   .el-date-editor--daterange.el-input, .el-date-editor--daterange.el-input__inner, .el-date-editor--timerange.el-input, .el-date-editor--timerange.el-input__inner {
  //     width: 247px;
  // }
}
</style>
